@use '@/styles/utils/mixins.scss' as *;

.corporateTextWrapper {
  display: flex;
  gap: 2.5rem;

  @include mob() {
    display: flex;
    flex-direction: column-reverse;
  }

  .rightContent {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    @include mob() {
      flex-direction: column;
      align-items: flex-start;
      gap: 3.75rem;
    }
  }

  .leftContent {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bannerImageContainer {
    overflow: hidden;
    border-radius: 1.25rem;
  }
}
